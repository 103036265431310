import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    document.title = "Deep Link";
  }, []);
  return (
    <Container>
      <div className="text-center display-2 font-weight-bold text-dark">Deep Link</div>
      <h1 className="text-center text-dark">Available DeepLinks</h1>
      <div className="text-center">
        <Link to="/youtube" className="btn btn-primary">
          Youtube Deep Link
        </Link>
      </div>
    </Container>
  );
};

export default Home;
